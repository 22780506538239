
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { settingsStateMapGetter } from '@/services/settingsService'
import { getMetaTags } from '@/utils'
import PageTitle from '@/components/PageTitle'

export default {
  name: 'Login',
  middleware: 'authRedirect',
  layout: 'auth',
  nuxtI18n: {
    paths: {
      en: '/login',
      nl: '/inloggen',
      it: '/accedi',
      de: '/anmeldung',
      fr: '/s-identifier',
      es: '/iniciar-sesion'
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    PageTitle
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      backendError: 'is-hidden',
      isClosable: false
    }
  },
  computed: {
    ...settingsStateMapGetter()
  },
  methods: {
    getPageUrl() {
      const domain = this.settings.domain.name
      return `https://${domain}${this.$route.path}`
    },
    async userLogin() {
      const loadingComponent = this.$buefy.loading.open()
      await this.$auth
        .loginWith('local', {
          data: this.form
        })
        .then(() => {
          this.$router.push(
            this.localePath({
              name: 'race-myprediction',
              params: {
                race: this.selectedRace.slug
              }
            })
          )
          loadingComponent.close()
          this.$buefy.toast.open(
            this.$t('page_login_form_toast_message_success')
          )
        })
        .catch(err => {
          loadingComponent.close()
          this.$buefy.toast.open(
            this.$t('page_login_form_toast_message_failure')
          )
          this.$refs.observer.setErrors(err.response.data.errors)
          this.backendError = 'is-visible'
          this.$sentry.captureException(err)
        })
    },
    hideBackendError() {
      this.backendError = 'is-hidden'
    }
  },
  head() {
    const title = this.$t('page_login_title')
    const description = this.$t('page_login_seo_description', {
      title: this.settings.domain.title
    })
    const pageUrl = this.getPageUrl()
    const settings = this.settings

    return getMetaTags(title, description, pageUrl, settings)
  }
}
